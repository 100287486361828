"use strict";
class GlRenderTarget {
  constructor() {
    this.width = -1;
    this.height = -1;
    this.msaa = false;
    this.msaaRenderBuffer = [];
  }
}

export { GlRenderTarget };

